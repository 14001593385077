import React, { useContext, useEffect, useState } from "react";
import ParamContext from "../contexts/paramContext";
import Icon from "@mdi/react";
import { mdiAccountCircle } from "@mdi/js";
import "../style/global.css";

const Avatar = ({
  id,
  imgUrl,
  ext,
  onClick,
  update,
  width = "40px",
  height = "40px",
  style,
}) => {
  const param = useContext(ParamContext);
  const eid = id;
  const [imageSrc, setImageSrc] = useState(null);

  useEffect(() => {
    const loadImage = async () => {
      let foundImage = false;

      const imageUrl = imgUrl + id + "." + ext;
      try {
        await loadImageAsync(imageUrl);
        setImageSrc(imageUrl);
        foundImage = true;
      } catch (error) {}
      if (!foundImage) {
        setImageSrc(null);
      }
    };
    loadImage();
  }, [eid, param.urlimg, update, ext, id, imgUrl]);

  const loadImageAsync = (url) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => resolve();
      img.onerror = () => reject();
      img.src = url;
    });
  };

  return imageSrc ? (
    <div
      className="userprofile-pic"
      onClick={onClick}
      style={{ width, height }}
    >
      <img src={imageSrc} alt="" className="avatar" style={{ width, height }} />
    </div>
  ) : (
    <div
      className="userprofile-empty"
      onClick={onClick}
      style={{ width, height }}
    >
      <Icon
        path={mdiAccountCircle}
        // size="50px"
        style={{ width, height, ...style }}
        className="account-circle-icon"
      />
    </div>
  );
};

export default Avatar;
// import React, { useContext, useEffect, useState, memo } from "react";
// import ParamContext from "../contexts/paramContext";
// import Icon from "@mdi/react";
// import { mdiAccountCircle } from "@mdi/js";
// import "../style/global.css";

// const Avatar = ({ id, imgUrl, ext, onClick, update }) => {
//   const param = useContext(ParamContext);
//   const eid = id;
//   const [imageSrc, setImageSrc] = useState(null);

//   useEffect(() => {
//     const loadImage = async () => {
//       let foundImage = false;

//       const imageUrl = imgUrl + id + "." + ext;
//       try {
//         await loadImageAsync(imageUrl);
//         setImageSrc(imageUrl);
//         foundImage = true;
//       } catch (error) {}
//       if (!foundImage) {
//         setImageSrc(null);
//       }
//     };
//     loadImage();
//   }, [eid, param.urlimg, update, ext, id, imgUrl]);

//   const loadImageAsync = (url) => {
//     return new Promise((resolve, reject) => {
//       const img = new Image();
//       img.onload = () => resolve();
//       img.onerror = () => reject();
//       img.src = url;
//     });
//   };

//   return imageSrc ? (
//     <div className="userprofile-pic" onClick={onClick}>
//       <img src={imageSrc} alt="" className="avatar" />
//     </div>
//   ) : (
//     <div className="userprofile-empty" onClick={onClick}>
//       <Icon
//         path={mdiAccountCircle}
//         size="50px"
//         className="account-circle-icon"
//       />
//     </div>
//   );
// };

// export default memo(Avatar);
